import React, { useEffect } from 'react'
import { BaseLayout } from '@layouts/base'
import { graphql, Link, PageProps } from 'gatsby'
import { BlogSidebar } from '@components/blogSidebar'
import '@styles/blog.scss'

// This file is used in gatsby-node to generate blog post index pages

const slugifyBlogPost = (name: string) => {
  return name.replace('-', '/').replace(/-[0-9]+-/, '/')
}

type DataProps = {
  allFile: {
    nodes: {
      id: string
      name: string
      childMdx?: {
        excerpt: string
        frontmatter: {
          date: string
          title: string
        }
      }
    }[]
  }
  site: {
    siteMetadata: {
      prodSiteUrl: string
      disqusShortName: string
    }
  }
}

type ContextProps = {
  skip: number
  limit: number
  numPages: number
  currentPage: number
}

const BlogIndex: React.FC<PageProps<DataProps, ContextProps>> = ({ data, pageContext }) => {
  const { numPages, currentPage } = pageContext

  const hasPrevPage = currentPage > 1
  const hasNextPage = currentPage < numPages

  useEffect(() => {
    const commentCountScript = document.createElement('script')
    commentCountScript.src = `//${data.site.siteMetadata.disqusShortName}.disqus.com/count.js`
    commentCountScript.id = 'dsq-count-scr'
    commentCountScript.async = true

    document.body.appendChild(commentCountScript)
  }, [])

  const posts = data.allFile.nodes.map(({ id, name, childMdx }) => {
    if (!childMdx) return
    const { frontmatter, excerpt } = childMdx
    const slug = slugifyBlogPost(name)
    return (
      <div className='blog-post' key={id}>
        <h2 className='blog-post__title blog-post__title--link'>
          <Link to={`/blog/${slug}`}>{frontmatter.title}</Link>
        </h2>
        <div className='blog-post__comment-count'>
          <Link to={`/blog/${slug}#disqus_thread`} data-disqus-identifier={`/blog/${slug}`}>
            0 Comments
          </Link>
        </div>
        <div className='blog-post__content text-content'>
          <p>
            {excerpt}{' '}
            <Link to={`/blog/${slug}`} className='blog-post__more-link'>
              Continue reading
            </Link>
          </p>
        </div>
      </div>
    )
  })

  return (
    <BaseLayout title='Blog' pageIsResponsive>
      <section className='page-content'>
        <div className='page-content__container container'>
          <h1 className='page-content__title'>
            <Link to='/blog'>Blog</Link>
          </h1>

          <div className='sidebar-layout'>
            <div className='sidebar-layout__main'>
              {posts}
              <div className='blog-pager'>
                {hasPrevPage && (
                  <Link
                    to={currentPage === 2 ? '/blog' : `/blog/${currentPage - 1}`}
                    rel='prev'
                    className='blog-pager__prev'
                  >
                    Newer Posts
                  </Link>
                )}
                {hasNextPage && (
                  <Link to={`/blog/${currentPage + 1}`} rel='next' className='blog-pager__next'>
                    Older Posts
                  </Link>
                )}
              </div>
            </div>
            <BlogSidebar />
          </div>
        </div>
      </section>
    </BaseLayout>
  )
}

export const pageQuery = graphql`
  query blogIndexQuery($skip: Int!, $limit: Int!) {
    allFile(
      filter: { sourceInstanceName: { eq: "blog" }, ext: { eq: ".mdx" } }
      sort: { fields: childrenMdx___frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        name
        childMdx {
          excerpt
          frontmatter {
            date
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        prodSiteUrl
        disqusShortName
      }
    }
  }
`

export default BlogIndex
